import React from 'react';
import { FaFacebookF, FaLinkedin } from 'react-icons/fa';
import { BiCopy } from 'react-icons/bi';
import { toast } from 'react-toastify';

const copyCurrentUrl = () => {
  const currentUrl = window.location.href;
  navigator.clipboard.writeText(currentUrl);
  toast.success('Link copied to clipboard!', {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const CopyLinkButton = () => {
  return (
    <div onClick={copyCurrentUrl}>
      <BiCopy className="w-5 h-5 text-deehiy" />
    </div>
  );
};

const BusinessProfile = () => {
  // const mediumTitle = 'text-xl sm:text-3xl font-bold pb-4 ';

  return (
    <div className="mx-auto pt-6 sm:pt-12  container px-4 sm:px-20 flex flex-col">
      <div className="w-full sm:w-3/5 mx-auto">
        <div className="text-2xl sm:text-5xl font-bold pb-8">
          Elevate Your Real Estate Agency with Deehiy Business Profiles
        </div>
        <div className=" flex space-x-4 text-md text-gray-600">
          by Deehiy, {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}
        </div>
        <div className=" flex space-x-6 py-6">
          <a href="https://www.facebook.com/buydeehiy/" target="_blank" className="text-deehiy">
            <FaFacebookF className="w-5 h-5 text-deehiy" />
          </a>
          <a href="https://www.linkedin.com/company/deehiy/mycompany/" target="_blank">
            <FaLinkedin className="w-5 h-5 text-deehiy" />
          </a>
          <CopyLinkButton />
        </div>

        <div className="pt-4 font-bold text-xs sm:text-sm">
          Some of Thailand's most prominent real estate agencies have already joined Deehiy.
          Agencies like Remax, VR Global Property, and Baan Hunt are leveraging Deehiy's platform to
          connect with clients more effectively and streamline their operations.
        </div>
        <div className="flex justify-center w-full p-2 sm:p-6 items-center">
          <img alt="" src={'/images/business-news.png'} className="w-full" />
        </div>
        {/* <div className="flex justify-center w-4/5 mx-auto p-2 sm:p-6 items-center">
          <img alt="" src={'/images/business-news.png'} className="w-full" />
        </div> */}

        <div className="pt-4 font-bold text-xs sm:text-sm">
          In a digital age, traditional real estate agencies face the challenge of staying
          competitive while retaining their unique touch. Deehiy has the solution: Deehiy Business
          Profiles, a groundbreaking tool designed specifically for traditional agencies.
        </div>
        <div className="pt-6 text-xs font-bold sm:text-sm">Unified Digital Presence</div>
        <div className="pt-3 text-xs sm:text-sm">
          Deehiy Business Profiles offer a single hub for your agency's digital presence. Showcase
          your expertise, portfolio, and services in one place, streamlining your online identity
          and making it easier for clients to find you.
        </div>
        <div className="pt-6 text-xs font-bold sm:text-sm">Effortless Team Collaboration</div>
        <div className="pt-3 text-xs sm:text-sm">
          Traditional agencies often involve multiple team members. Deehiy Business Profiles
          simplify collaboration, ensuring everyone is on the same page. Real-time updates,
          messaging, and shared calendars keep your team coordinated and focused on delivering
          exceptional service.
        </div>
        <div className="pt-6 text-xs font-bold sm:text-sm">Simplified Property Management</div>
        <div className="pt-3 text-xs sm:text-sm">
          Listing and managing properties becomes a breeze with Deehiy Business Profiles. Create
          detailed listings with ease, supported by advanced AI that targets the right audience.
          This means faster sales and happier clients.
          <br />
          <br />
        </div>
        <br />
        <br />
      </div>
      <br />
    </div>
  );
};

export default BusinessProfile;
