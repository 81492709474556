import React, { useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../utils/helper';

const PhoneVerification = ({ phone, isPhoneVerified, isSaved, onVerified }: any) => {
  // const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState(1); // 1: Enter phone, 2: Enter OTP
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sendOtp = async () => {
    setIsLoading(true); // Start loader
    try {
      const response = await axios.post(`${baseUrl}/api/sms/send-otp`, { phone });
      setMessage(response.data.message);
      setStep(2);
    } catch (error: any) {
      setMessage(error.response?.data?.message || 'Failed to send OTP');
    } finally {
      setIsLoading(false); // Stop loader
    }
  };

  const verifyOtp = async () => {
    setIsLoading(true); // Start loader

    try {
      const response = await axios.post(`${baseUrl}/api/sms/verify-otp`, {
        phone,
        code: otp,
      });
      setMessage(response.data.message);
      setStep(3);

      if (onVerified) {
        onVerified();
      }
    } catch (error: any) {
      setMessage(error.response?.data?.message || 'OTP verification failed');
    } finally {
      setIsLoading(false); // Stop loader
    }
  };

  return (
    <div>
      {step === 1 && (
        <>
          <div className="flex w-full items-center justify-between p-2">
            {phone && isPhoneVerified ? (
              <span className="bg-green-100 p-1"> ✅ This phone number is verified</span>
            ) : (
              <>
                <span className="bg-yellow-100 p-1"> Please, verify your phone number</span>

                <div className="text-gray-400 ">
                  {phone?.length > 6 && isSaved && (
                    <button
                      disabled={isLoading}
                      className=" whitespace-nowrap text-sm ml-12 bg-deehiy p-2 rounded text-white font-bold"
                      onClick={sendOtp}
                    >
                      {isLoading ? 'sending...' : 'Send OTP'}
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
      {step === 2 && (
        <div className="flex justify-between mx-2 items-center bg-white p-2">
          <div>
            <label className="text-deehiy font-bold">Enter OTP:</label>
            <input
              className="w-[96px] tracking-widest font-bold text-gray-700 ml-2 focus:ring-0 focus:outline-none border-deehiy-light focus:ring-none focus:border-deehiy ring-0 rounded bg-yellow-50 placeholder-gray-300"
              type="text"
              maxLength={6}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="123456"
            />
          </div>
          {otp.length === 6 && (
            <button
              disabled={isLoading}
              className="ml-12 bg-deehiy  whitespace-nowrap  p-2 rounded text-white font-bold"
              onClick={verifyOtp}
            >
              {isLoading ? 'verifying...' : 'Verify OTP'}
            </button>
          )}
        </div>
      )}
      {step === 3 && (
        <div>
          <span className="bg-green-100 p-1"> ✅ This phone number is verified</span>
          {/* {setShowModal && (
            <button className="secondary-btn" onClick={() => setShowModal(false)}>
              Close
            </button>
          )} */}
        </div>
      )}
      {message && <p className="bg-white py-1 text-gray-600 px-2 text-xs">{message}</p>}
    </div>
  );
};

export default PhoneVerification;
