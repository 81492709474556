import React, { useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from '../../contexts/global';
import ChatButton from './ChatButton';
import { toast } from 'react-toastify';

import { MdPhone, MdEmail } from 'react-icons/md';
import Modal2 from '../listing/Modal2';
import { LuContact } from 'react-icons/lu';
import { Interaction, SourcePage } from '../../types/types';
import { createInteraction } from '../../services/auth/userService';

interface ContactProps {
  chatButtonHandler: () => void; // Function type for a button click handler
  contact: any; //User type with isBusiness + userId
  sourcePage: SourcePage;
  businessId?: number;
  section?: string;
}

const Contact = ({ chatButtonHandler, contact, sourcePage, section }: ContactProps) => {
  const { currentUser } = useAuth();
  const { isWL } = useGlobal();

  const navigate = useNavigate();
  const [showPhoneModal, setShowPhoneModal] = useState(false);

  const handleButtonClick = () => {
    if (!currentUser && !isWL) {
      navigate('/login');
    } else {
      setShowPhoneModal(true);
    }
  };

  const handleInteraction = async (buttonType: 'email' | 'phone' | 'chat') => {
    const interaction: Interaction = {
      userId: currentUser?.id,
      contactedUserId: contact.isBusiness ? contact.userId : contact.id, // check if business
      buttonType,
      deviceType: window.innerWidth <= 768 ? 'mobile' : 'desktop',
      sourcePage,
      section,
      businessId: contact.isBusiness ? contact.id : null,
    };
    try {
      await createInteraction(interaction);
      console.log('Interaction recorded:', interaction);
    } catch (error) {
      console.error('Error creating interaction:', error);
    }
  };

  return (
    <div>
      <button
        title="Contact"
        className="primary-btn m-auto text-white rounded-full flex gap-x-2 justify-center items-center w-[100px] sm:w-[130px]"
        onClick={handleButtonClick}
      >
        <LuContact className="text-white h-5 w-5" /> Contact
      </button>
      {showPhoneModal && (
        <div className="absolute top-0 left-0 w-full h-full">
          <Modal2 showModal={showPhoneModal} setShowModal={setShowPhoneModal}>
            <div className="px-10 pb-10 flex space-y-6 flex-col items-center justify-center">
              <div className="font-bold flex flex-col text-center text-base sm:text-lg">
                Get in touch
              </div>
              <div className="flex justify-center items-center pt-8 gap-x-8">
                <ChatButton
                  chatButtonHandler={() => {
                    chatButtonHandler();
                    handleInteraction('chat');
                  }}
                />{' '}
                {contact?.isPhonePublic && contact?.phone && (
                  <a
                    href={`tel:${contact.phone}`}
                    onClick={() => handleInteraction('phone')}
                    className="primary-btn m-0 font-bold flex gap-x-2 w-[100px] ring-0 overflow-hidden border-none"
                  >
                    <MdPhone className="text-white h-5 w-5" />
                    Call
                  </a>
                )}
                {contact?.email && (
                  <a
                    href={`mailto:${contact.email}`}
                    title={contact.email}
                    onClick={() => {
                      navigator.clipboard.writeText(contact.email);
                      toast.success('Email copied to clipboard!');
                      handleInteraction('email');
                    }}
                    className="primary-btn m-0 font-bold flex gap-x-2 w-[100px] ring-0 overflow-hidden border-none"
                  >
                    <MdEmail className="text-white h-5 w-5" />
                    Email
                  </a>
                )}
              </div>
            </div>
          </Modal2>
        </div>
      )}
    </div>
  );
};

export default Contact;
