import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/auth';
import Loader from '../Loader';
import { format } from 'date-fns'; // For formatting the date
import { FaExternalLinkAlt } from 'react-icons/fa';
import { fetchPaymenthistory } from '../../../services/paymentService';
import { MdOutlineCalendarViewDay } from 'react-icons/md';

const PaymentHistory = () => {
  const { currentUser } = useAuth();
  const [payments, setPayments] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await fetchPaymenthistory();

        setPayments(response.data!); // Ensure your backend returns an array of payments
      } catch (err) {
        setError('No payment ID');
        setLoading(false);

        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, [currentUser]);

  if (loading) return <Loader />;

  return (
    <div className=" mt-10 p-8 space-y-12 rounded shadow-md w-[950px] bg-white">
      <div className="text-xl font-bold w-full border border-transparent border-b-gray-200 space-x-2 flex justify-left items-center">
        <MdOutlineCalendarViewDay />
        <span>Payment History</span>
      </div>
      {error ? (
        <p className="text-gray-500">{error}</p>
      ) : payments.length === 0 ? (
        <p>No payment history available.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="border p-3 text-left">Date</th>
                <th className="border p-3 text-left">Amount</th>
                <th className="border p-3 text-left">Status</th>
                <th className="border p-3 text-left">Payment Method</th>
                <th className="border p-3 text-left">Receipt</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id} className="border-b hover:bg-gray-50">
                  <td className="border p-3">
                    {format(new Date(payment.created * 1000), 'MMM dd, yyyy')}
                  </td>
                  <td className="border p-3">${(payment.amount / 100).toFixed(2)}</td>
                  <td
                    className={`border p-3 font-semibold ${
                      payment.status === 'succeeded' ? 'text-green-400' : 'text-red-400'
                    }`}
                  >
                    {payment.status}
                  </td>
                  <td className="border p-3">
                    {payment.payment_method.brand.toUpperCase()} •••• {payment.payment_method.last4}
                  </td>
                  <td className="border p-3">
                    {payment.receipt_url ? (
                      <a
                        href={payment.receipt_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-400 hover:text-deehiy hover:underline flex items-center"
                      >
                        View Receipt <FaExternalLinkAlt className="ml-1" />
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PaymentHistory;
