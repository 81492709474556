import http from './httpService';

const paymentEndpoint = '/api/payment';

export const createSetupIntent = () => {
  return http.post(`${paymentEndpoint}/create-setup-intent`, { credentials: 'include' });
};

export const deletePaymentMethod = (paymentMethodId: number) => {
  return http.delete(`${paymentEndpoint}/delete-payment-method/${paymentMethodId}`);
};

export const fetchSavedPaymentMethods = () => {
  return http.get(`${paymentEndpoint}/get-payment-methods`);
};

export const savePaymentMethodToDatabase = async (data: any) => {
  return http.post(`${paymentEndpoint}/save-method`, data);
};

export const buyLead = async (data: any) => {
  return http.post(`${paymentEndpoint}/buy-lead`, data);
};

export const setDefaultPaymentMethod = async (paymentMethodId: string) => {
  return http.post(`${paymentEndpoint}/payment-methods/default`, {
    paymentMethodId,
  });
};

export const fetchPaymenthistory = async () => {
  return http.get(`${paymentEndpoint}/history`);
};
