import React, { useState, useEffect } from 'react';
import { FaFacebookF, FaLinkedin } from 'react-icons/fa';
import { BiCopy } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { articles } from '../../data/articles';
import { useParams } from 'react-router-dom';
import SEO from '../SEO/SEO';
import TopMobileNavigation from '../main/navigation/TopMobileNavigation';
import Share from '../main/Share';
import { Dropdown } from 'flowbite-react';
import { BsFillShareFill } from 'react-icons/bs';
import { getArticleById } from '../../services/articleService';
import Loader from '../main/Loader';
import { ArticleContent } from '../../types/types';

const copyCurrentUrl = () => {
  const currentUrl = window.location.href;
  navigator.clipboard.writeText(currentUrl);
  toast.success('Link copied to clipboard!', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const CopyLinkButton = () => {
  return (
    <div onClick={copyCurrentUrl}>
      <BiCopy className="w-5 h-5 text-deehiy" />
    </div>
  );
};

const ShareBtn = ({ article }: any) => {
  return (
    <Dropdown
      arrowIcon={false}
      inline={true}
      placement="bottom-end"
      label={
        <div className=" hover:text-deehiy flex text-base sm:text-lg gap-3 justify-center items-center">
          <BsFillShareFill className="my-auto w-4 h-4 text-deehiy sm:w-4 sm:h-4" />
          <span className="hidden sm:flex">Share</span>{' '}
        </div>
      }
    >
      <Dropdown.Header>
        <Share type="article" id={article.id} />
      </Dropdown.Header>
    </Dropdown>
  );
};

const Article = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [article, setArticle] = useState<ArticleContent>({
    id: 0,
    date: '',
    category: '',
    title: '',
    keywords: '',
    image: '',
    preview: '',
    text: '',
  });

  useEffect(() => {
    setIsLoading(true);
    getArticleById(Number(id)).then((res) => {
      setArticle(res.data);
      setIsLoading(false);
    });
  }, [id]);

  if (isLoading) return <Loader />;

  return (
    <div className="mx-auto pt-8 sm:pt-12  container px-4 sm:px-20 flex flex-col">
      <SEO
        title={articles[0]?.title!}
        description={articles[0]?.text!}
        name="Help center"
        type="article"
        image={articles[0]?.image!}
      />
      <TopMobileNavigation btn={<ShareBtn article={article} />} />
      <div>
        <div className="w-full sm:w-3/5 mx-auto">
          <div className="text-2xl sm:text-5xl font-bold pb-2 sm:pb-8">{article?.title}</div>
          <div className=" flex space-x-4 text-md text-gray-600">by Deehiy, {article?.date}</div>
          <div className=" flex space-x-6 py-2 sm:py-6">
            <a href="https://www.facebook.com/buydeehiy/" target="_blank" className="text-deehiy">
              <FaFacebookF className="w-5 h-5 text-deehiy" />
            </a>
            <a href="https://www.linkedin.com/company/deehiy/mycompany/" target="_blank">
              <FaLinkedin className="w-5 h-5 text-deehiy" />
            </a>
            <CopyLinkButton />
          </div>
        </div>
        {article?.image && (
          <>
            <div className="flex w-4/5 justify-center mx-auto p-0 sm:p-6 rounded-2xl items-center">
              <img alt="" src={article?.image} className="object-cover max-h-[400px] rounded-2xl" />
            </div>
            <br />
          </>
        )}
        <br />
        <div className="w-full sm:w-3/5 mx-auto">
          {article && <div dangerouslySetInnerHTML={{ __html: article.text }} />}
        </div>
      </div>

      <br />
      <br />
      <br />
    </div>
  );
};

export default Article;
