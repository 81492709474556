import clsx from 'clsx';
import { MouseEvent, useCallback, useEffect, useState, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { FaPlus } from 'react-icons/fa';
import uuid from 'react-uuid';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ImagePropertyUpload } from '../../utils/uploader';

import PreviewList from './Preview/PreviewList';
import { DragDropContext } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
// import { FilePreview } from "./FilePreview"
// import { FileWithPreview } from '../../types/types';

type DropzoneInputProps = {
  accept?: string;
  helperText?: string;
  id: string;
  type: string;
  label: string;
  maxFiles?: number;
  minFiles?: number;
  readOnly?: boolean;
  validation?: object;
  uploadedFiles: FileStateObject[];
  setUploadedFiles: any;
};

// export type UploadedFile = {
//   id: string;
//   publicId: string;
//   url: string;
// };

export type FileStateObject = {
  id: string;
  file: File;
  preview: string;
  uploaded: boolean;
  progress: number;
  publicId?: string;
  url?: string;
};

export default function DropzoneInput({
  helperText = '',
  id,
  type,
  label,
  maxFiles = 1,
  minFiles = 1,
  validation,
  readOnly,
  uploadedFiles = [],
  setUploadedFiles,
}: DropzoneInputProps) {
  const {
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();

  const MAXSIZE = Number(process.env.REACT_APP_MAX_SIZE) || 5242880;
  const fileInput = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<FileStateObject[]>(uploadedFiles);
  const [isReordered, setIsReordered] = useState(false);
  // const [previewFiles, setPreviewFiles] = useState(selectedFiles);

  const uploadNewFiles = async (droppedFiles: FileStateObject[]) => {
    setFiles(files ? [...files, ...droppedFiles].slice(0, maxFiles) : droppedFiles);

    // const uploadedArr: UploadedFile[] = [];
    const uploadedArr: FileStateObject[] = [];

    const uploadPromises = droppedFiles.map(async (file) => {
      const uploadedFile = await ImagePropertyUpload(file.file, (progress: number) => {
        setFiles((prevFiles: any[]) =>
          prevFiles.map((prevFile) => {
            if (prevFile.id === file.id) {
              return { ...prevFile, progress };
            }
            return prevFile;
          })
        );
      });

      if (!uploadedFile) {
        return;
      }

      if (uploadedFile) {
        uploadedArr.push({
          ...file,
          id: file.id,
          uploaded: true,
          progress: 100,
          publicId: uploadedFile.publicId,
          url: uploadedFile.secureUrl,
        });
      }
    });

    try {
      await Promise.all(uploadPromises);
      setUploadedFiles([...uploadedFiles, ...uploadedArr]);
      toast.success('Upload completed successfully!', { autoClose: 400 });
      // onUpload(uploadedFiles);
      clearErrors(id);
    } catch (error) {
      console.error(error);
      toast.error('Error uploading files');
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: any, rejectedFiles: any) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        setValue(id, uploadedFiles ? [...uploadedFiles] : null);

        if (rejectedFiles[0].file.size > MAXSIZE) {
          setError(id, {
            type: 'manual',
            message: `The maximum supported file size is ${Math.floor(MAXSIZE / 1048576)} MB`,
          });
        } else {
          setError(id, {
            type: 'manual',
            message: rejectedFiles && rejectedFiles[0].errors[0].message,
          });
        }
      } else {
        const totalFiles = (uploadedFiles ? uploadedFiles.length : 0) + acceptedFiles.length;
        if (totalFiles > maxFiles) {
          setError(id, {
            type: 'manual',
            message: `The maximum number of files allowed is ${maxFiles}`,
          });
          return;
        }

        const acceptedFilesPreview = acceptedFiles.map((file: File) => ({
          file,
          id: uuid(),
          preview: URL.createObjectURL(file),
          uploaded: false,
          progress: 0,
        }));

        uploadNewFiles(acceptedFilesPreview);

        setValue(
          id,
          uploadedFiles ? [...uploadedFiles, ...acceptedFiles].slice(0, maxFiles) : acceptedFiles,
          {
            shouldValidate: true,
          }
        );
        clearErrors(id);
      }
    },
    [clearErrors, files, uploadedFiles, id, maxFiles, setError, setValue]
  );

  const deleteFile = (e: MouseEvent, file: FileStateObject) => {
    e.preventDefault();
    const newFiles = [...files];
    const newUploadFiles = [...uploadedFiles];
    const indexToRemove = newFiles.indexOf(file);
    newFiles.splice(indexToRemove, 1);
    newUploadFiles.splice(indexToRemove, 1);

    setUploadedFiles(newUploadFiles);

    if (newFiles.length > 0) {
      // setFiles(newFiles)
      setFiles(newFiles); // for parents components
      setValue(id, newFiles, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      // setFiles([])
      setFiles([]); // for parents components
      setValue(id, null, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) return;
    if (source.index === destination.index && source.droppableId === destination.droppableId)
      return;

    setFiles((prev: any) => reorder(prev, source.index, destination.index));
    setIsReordered(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] }, // modified
    maxFiles,
    maxSize: MAXSIZE,
    noClick: files?.length > 0, // do not allow click if one file has already been uploaded
  });

  const handleClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;

    if (!selectedFiles) return;
    const fileStateObjects: FileStateObject[] = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      if (file.size > MAXSIZE) {
        setError(id, {
          type: 'manual',
          message: `The maximum supported file size is ${Math.floor(MAXSIZE / 1048576)} MB`,
        });
        // Optionally, clear the file input if a file exceeds the max size
        if (fileInput.current) fileInput.current.value = '';
        return;
      }

      fileStateObjects.push({
        id: uuid(),
        file,
        preview: URL.createObjectURL(file),
        uploaded: false,
        progress: 0,
      });
    }

    uploadNewFiles(fileStateObjects); // Call upload function with file data
  };

  useEffect(() => {
    if (isReordered) {
      // when files order change, we need to set uploadedFiles in the same order
      const sortedUploadedFiles = files.map((file) =>
        uploadedFiles.find((uploadedFile) => uploadedFile.id === file.id)
      );
      setUploadedFiles(sortedUploadedFiles as FileStateObject[]);
      setIsReordered(false);
    }
  }, [isReordered]);

  // useEffect(() => {
  //   if (uploadedFiles.length > 0) {
  //     setFiles(uploadedFiles as FileStateObject[]);
  //   }
  // }, []);

  return (
    <div>
      <label className="block text-sm sm:text:md lg:text-lg font-normal" htmlFor={id}>
        {label}
      </label>

      {readOnly && !(files?.length > 0) ? (
        <div className="py-3 pl-3 pr-4 text-sm sm:text-md lg:text-md font-normal border border-gray-300 divide-y divide-gray-300 rounded-md">
          {t('No file uploaded')}
        </div>
      ) : (
        <>
          {files?.length >= 1 && (
            <div className="flex justify-between mb-3">
              <div>
                <div className="text-sm sm:text:md lg:text-lg font-light">
                  <p>{t('Ta-Da! How does this look?')}</p>
                </div>
                <div className="text-sm sm:text:md lg:text-md font-extralight">
                  {t('drag to re-order')}
                </div>
              </div>
              <label htmlFor="fileInput">
                <button
                  onClick={handleClick}
                  className="flex justify-center items-center whitespace-nowrap shadow-lg rounded-full my-auto py-2 gap-x-1 px-3  bg-white border border-deehiy duration-200 hover:shadow-sm hover:text-deehiy"
                >
                  <FaPlus className="w-4 h-4" /> {t('add more')}
                </button>
                <input
                  ref={fileInput}
                  type="file"
                  id="fileInput"
                  hidden
                  multiple
                  onChange={handleFileUpload}
                />
              </label>
            </div>
          )}

          <Controller
            control={control}
            name={id}
            rules={validation}
            render={(controllerProps) => (
              <>
                <div
                  className=" focus:outline-none focus:ring-dark-400 group"
                  {...getRootProps()}
                  {...controllerProps}
                >
                  <input {...getInputProps()} className="bg-red-500 border-4 border-red-700" />

                  {files?.length >= 1 ? (
                    <>
                      <div
                        className={`${
                          files?.length >= 1 ? 'h-[24vh] sm:h-[45vh] border' : 'h-0 border-none'
                        }  border-gray-300 rounded-lg overflow-auto custom-scrollbar`}
                      >
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <PreviewList
                            deleteFile={deleteFile}
                            // files={previewFiles}
                            files={files}
                            readOnly={readOnly}
                            type={type} //floorplan or images
                          />
                        </DragDropContext>
                      </div>
                    </>
                  ) : (
                    <>
                      <span className="text-sm sm:text:md lg:text-lg font-light">
                        {t('Upload')}{' '}
                        <b>{id === 'floorplans' ? t('Floor Plans') : t('Property images')}</b>{' '}
                        {t('here')}
                      </span>

                      <div
                        className={clsx(
                          'w-full rounded-2xl p-2 bg-gray-100 border border-black cursor-pointer',
                          errors[id]
                            ? 'border-red-500 group-focus:border-red-500'
                            : 'group-focus:border-deehiy'
                        )}
                      >
                        <div className={'my-3 sm:my-14 space-y-2 text-center'}>
                          <p className="text-gray-500">
                            <div className="hidden sm:block text-center">
                              {t('Drag &apos;n&apos; drop some files here, or')}
                            </div>
                            <span>{t('click to select files')}</span>
                          </p>
                          <p className="text-xs text-deehiy">
                            {t('choose between {{minFiles}} to {{maxFiles}} images', {
                              minFiles,
                              maxFiles,
                            })}
                          </p>
                          <p className="text-xs text-deehiy">
                            {t('{{remainingFiles}} file(s) remaining', {
                              remainingFiles: maxFiles - (files?.length || 0),
                            })}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="mt-1">
                  {helperText !== '' && <p className="text-xs text-gray-500">{helperText}</p>}
                  {errors[id] && (
                    <p className="text-sm text-red-500">{errors[id]?.message?.toString()}</p>
                  )}
                </div>
              </>
            )}
          />
        </>
      )}
    </div>
  );
}
