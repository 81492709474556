import { slugify } from './textValidater';

interface PropertyURLParams {
  isWL: boolean;
  businessId?: number | string;
  country: string;
  city: string;
  propertyTitle: string;
  propertyId: number | string;
  isPreview?: boolean;
}

export const generatePropertyURL = ({
  isWL,
  businessId,
  country,
  city,
  propertyTitle,
  propertyId,
  isPreview,
}: PropertyURLParams) => {
  if (isPreview) return `/properties/preview/${propertyId}/${slugify(propertyTitle)}`;

  const pathBusiness = isWL ? `/business/${businessId}` : '';
  const countrySlug = country ? slugify(country) : 'country';
  const citySlug = city ? slugify(city) : 'city';
  const titleSlug = propertyTitle ? slugify(propertyTitle) : 'property';

  return `/homes${pathBusiness}/${countrySlug}/${citySlug}/${titleSlug}/${propertyId}`;
};
