import React, { useState, useEffect } from 'react';
import { useHunt } from '../../../contexts/hunt';
import { useAuth } from '../../../contexts/auth';
import { useParams, useNavigate } from 'react-router-dom';
// import MatchTable from './table/MatchTable';
// import TakeMeBack from '../../main/navigation/TakeMeBack';
// import { useTranslation } from 'react-i18next';
import Loader from '../../main/Loader';
import { BiChevronRight } from 'react-icons/bi';
import { initialHunt } from '../../../initialStates/initialStates';

const MatchesPerHunt = () => {
  const { id } = useParams();
  const { fetchHuntByIdContext } = useHunt();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  // const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [hunt, setHunt] = useState(initialHunt);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      // authentication check
      fetchHuntByIdContext(Number(id))
        .then((fetchedHunt: any) => {
          if (fetchedHunt.userId !== currentUser?.id) {
            alert('you are not allowed to edit this hunt');
            navigate('/');
          } else {
            console.log(fetchedHunt.id);
            setHunt(fetchedHunt);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  if (isLoading) return <Loader />;

  if (!hunt?.matches || hunt?.matches.length === 0)
    return (
      <div className="container items-center justify-center mx-auto h-screen">
        <div className="py-4 sm:py-12 space-y-6 sm:space-y-12 ">
          <div className="text-sm font-bold sm:text-lg pt-2 sm:pt-4 text-center">
            No matches… yet! But don’t worry!
          </div>
          <div className="max-w-[600px] mx-auto space-y-6">
            <p>
              our community is already on the hunt, tracking down properties that fit your criteria.
            </p>
            <p>
              Want results faster? Tweak your hunt and cast a wider net. The perfect match could be
              just a click away!
            </p>
            <div className="flex justify-evenly pt-3 items-center">
              <button
                className="primary-btn mx-0 flex items-center justify-center"
                onClick={() => navigate('/users/addhunt')}
              >
                <span>Hunt again</span>
              </button>
              <button
                className="hover:underline flex items-center justify-center"
                onClick={() => navigate('/users/dashboard')}
              >
                <span>Go to dashboard</span>
                <BiChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="container items-center justify-center mx-auto h-screen">
      <div className="py-4 sm:py-12 space-y-6 sm:space-y-12 ">
        <div className="text-sm font-bold sm:text-lg pt-2 sm:pt-4 text-center">
          Awesome! We found listings that match your criteria.
        </div>
        <div className="max-w-[600px] mx-auto space-y-6">
          <p>
            We're just double-checking their availability and will be in touch shortly. Want to keep
            exploring? Click here to browse more listings.
          </p>
          <div className="flex justify-evenly pt-3">
            <button
              className="primary-btn mx-0 flex items-center justify-center"
              onClick={() => navigate('/')}
            >
              <span>Browse listings</span>
            </button>
            <button
              className="flex items-center justify-center"
              onClick={() => navigate('/users/dashboard')}
            >
              <span>Go to dashboard</span>
              <BiChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className="container mx-auto h-screen">
  //     <div className="py-4 sm:py-12 space-y-6 sm:space-y-12 ">
  //       <TakeMeBack loc={'/users/huntingboard'} />
  //       <div className="text-xl sm:text-2xl font-bold w-full ">{hunt.location}</div>

  //       <div className="text-sm font-bold sm:text-md pt-2 sm:pt-4">
  //         {t('listings_received', { count: hunt?.matches?.length || 0 })}
  //         {/* {currentHunt?.matches?.length && currentHunt?.matches?.length > 1 && 's'} */}
  //         <div className="text-sm font-normal pt-2 sm:pt-4">
  //           {t('Review these listings and reach out to the listing owner')}
  //         </div>
  //       </div>
  //     </div>
  //     <MatchTable hunt={hunt} />
  //   </div>
  //);
};

export default MatchesPerHunt;
