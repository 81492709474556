import React, { useState, useEffect } from 'react';
import { useProperty } from '../contexts/property';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import Loader from '../components/main/Loader';
import VerticalTab from '../components/dashboard/tabsContents/VerticalTab';
import { EditListingTab, Property } from '../types/types';

// tabs contents
import PriceContent from '../components/dashboard/tabsContents/PriceContent';
import AvailabilityContent from '../components/dashboard/tabsContents/AvailabilityContent';
import LocationContent from '../components/dashboard/tabsContents/LocationContent';
import ListingBasicContent from '../components/dashboard/tabsContents/ListingBasicContent';
import FeaturesContent from '../components/dashboard/tabsContents/FeaturesContent';
import ImagesContent from '../components/dashboard/tabsContents/ImagesContent';
import OwnerContent from '../components/dashboard/tabsContents/OwnerContent';
import { LAND } from '../constants/constant';
import { useTranslation } from 'react-i18next';
import { isUserAssignedToProperty } from '../utils/businessAccess';
import { generatePropertyURL } from '../utils/urlHelpers';

const EditListingPage = () => {
  const { fetchPropertyById, setCurrentProperty, currentProperty, modifyProperty } = useProperty();
  const { t } = useTranslation();

  const initialTabs: EditListingTab[] = [
    {
      id: 'tab1',
      title: 'Price',
      content: <PriceContent modifyProperty={modifyProperty} />,
    },
    {
      id: 'tab2',
      title: 'Listing basics',
      content: <ListingBasicContent modifyProperty={modifyProperty} />,
    },
    {
      id: 'tab3',
      title: 'Photos & plans',
      content: <ImagesContent modifyProperty={modifyProperty} />,
    },
    {
      id: 'tab4',
      title: 'Location',
      content: <LocationContent modifyProperty={modifyProperty} />,
    },
    {
      id: 'tab5',
      title: 'Features',
      content: <FeaturesContent modifyProperty={modifyProperty} />,
    },
    {
      id: 'tab6',
      title: 'Availability',
      content: <AvailabilityContent modifyProperty={modifyProperty} />,
    },
    {
      id: 'tab7',
      title: 'Owner details',
      content: <OwnerContent />,
    },
  ];

  const { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState(initialTabs);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      // authentication check
      fetchPropertyById(Number(id)).then((listing: Property) => {
        // if (listing.userId !== currentUser?.id) {
        if (!isUserAssignedToProperty(currentUser?.id, listing, currentUser?.roleId)) {
          alert('you are not allowed to edit this property');
          navigate(-1);
        } else {
          const imagesStrArray = listing.images?.map((img: any) => (img.url ? img.url : img));
          setCurrentProperty({ ...listing, images: imagesStrArray });
        }
        setIsLoading(false);
      });
    }
  }, [id]);

  useEffect(() => {
    // Define a variable to hold the new tabs
    let newTabs = [...initialTabs];

    // Hide the "Features" tab if the property type is LAND
    if (currentProperty?.propertyType === LAND) {
      const index = newTabs.findIndex((tab) => tab.title === 'Features');
      if (index !== -1) {
        newTabs.splice(index, 1);
      }
    }

    // Remove the "Availability" title under certain conditions
    if ((currentProperty?.isForSale && !currentProperty?.isForRent) || currentProperty?.isOffPlan) {
      newTabs = newTabs.filter((tab) => tab.title !== 'Availability');
    }

    // Set the new tabs
    setTabs(newTabs);
  }, [currentProperty?.propertyType, currentProperty?.isForRent, currentProperty?.isOffPlan]);

  if (isLoading) return <Loader />;

  return (
    <div className="container">
      <div className=" flex flex-col sm:flex-row items-center justify-between py-16 px-4 sm:px-20">
        <div className="text-3xl font-bold w-full sm:w-3/5 px-22">
          {currentProperty?.propertyTitle}
        </div>

        {/* publication status */}
        <div className="flex items-center justify-left sm:justify-center gap-2 sm:gap-4">
          {currentProperty?.published ? (
            <>
              <div className="w-3 h-3 sm:w-5 sm:h-5  bg-green-400 rounded-full" />
              <span className="text-gray-500">{t('Listing published')}</span>
            </>
          ) : (
            <>
              <div className="w-3 h-3 sm:w-5 sm:h-5 bg-orange-400 rounded-full" />
              <span className="text-gray-500">{t('Listing not published')}</span>
            </>
          )}
        </div>

        {/* preview listing button */}
        <button
          type="button"
          className="hidden sm:flex whitespace-nowrap rounded-xl mt-6 sm:mt-0 py-2 px-1 sm:px-3 font-bold bg-gray-100 hover:opacity-80 shadow-sm hover:shadow-none border border-gray-400"
          onClick={() =>
            window.open(
              generatePropertyURL({
                isWL: false,
                businessId: undefined,
                country: currentProperty.country,
                city: currentProperty.city,
                propertyId: Number(currentProperty.propertyId),
                propertyTitle: currentProperty.propertyTitle,
                isPreview: true,
              }),
              '_blank'
            )
          }
        >
          {t('Preview listing')}
        </button>
      </div>
      <VerticalTab tabs={tabs} />
    </div>
  );
};

export default EditListingPage;
