import React, { useState } from 'react';
import PaymentCard from './PaymentCard'; // Import your saved card component
import PaymentHistory from './PaymentHistory'; // Import your payment history component

const BillingPayment = () => {
  const [activeTab, setActiveTab] = useState('paymentCard');

  return (
    <div className="max-w-3xl mx-auto p-4">
      {/* Tabs */}
      <div className="flex border-b">
        <button
          className={`flex-1 py-2 text-center ${
            activeTab === 'paymentCard' ? 'border-b-2 border-deehiy font-semibold' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('paymentCard')}
        >
          Payment Cards
        </button>
        <button
          className={`flex-1 py-2 text-center ${
            activeTab === 'history' ? 'border-b-2 border-deehiy font-semibold' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('history')}
        >
          Payment History
        </button>
      </div>

      {/* Tab Content */}
      <div className="mt-2">{activeTab === 'history' ? <PaymentHistory /> : <PaymentCard />}</div>
    </div>
  );
};

export default BillingPayment;
