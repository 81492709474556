import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  createSetupIntent,
  deletePaymentMethod,
  fetchSavedPaymentMethods,
  savePaymentMethodToDatabase,
  setDefaultPaymentMethod,
} from '../../../services/paymentService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BsTrash } from 'react-icons/bs';
import { MdOutlinePayment } from 'react-icons/md';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const SaveCardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [savedCards, setSavedCards] = useState<any>([]);
  const [clientSecret, setClientSecret] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadSetupIntent = async () => {
      const secret = await createSetupIntent();
      console.log('secret.data', secret.data);
      setClientSecret(secret.data.clientSecret);
    };
    const loadSavedCards = async () => {
      const cards = await fetchSavedPaymentMethods();

      console.log('saveCards', cards);
      setSavedCards(cards.data);
    };
    loadSetupIntent();
    loadSavedCards();
  }, []);

  const handleSaveCard = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements || !clientSecret) return;

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      toast.error('Card element not found.');
      setLoading(false);
      return;
    }
    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: { card: cardElement },
    });

    if (error) {
      console.error(error);
    } else {
      // Extract payment method details
      const paymentMethodId = setupIntent.payment_method;
      console.log(setupIntent);
      // const paymentMethodDetails = await stripe.paymentMethods.retrieve(paymentMethodId);

      await savePaymentMethodToDatabase({
        paymentMethodId,
      });

      toast.success('Card saved successfully!');
      // setSavedCards([...savedCards, setupIntent.payment_method]);
      const cards = await fetchSavedPaymentMethods();
      setSavedCards(cards.data);
    }

    setLoading(false);
  };

  const handleSetDefault = async (paymentMethodId: any) => {
    try {
      await setDefaultPaymentMethod(paymentMethodId);
      toast.success('Default payment method updated successfully.');

      // Update the local state
      setSavedCards((prevCards: any) =>
        prevCards.map((card: any) => ({
          ...card,
          isDefault: card.paymentMethodId === paymentMethodId,
        }))
      );
    } catch (error) {
      console.error('Error setting default payment method:', error);
      alert('Failed to set default payment method.');
    }
  };

  const handleDeleteCard = async (paymentMethodId: any) => {
    try {
      await deletePaymentMethod(paymentMethodId);
      setSavedCards(savedCards.filter((card: any) => card.paymentMethodId !== paymentMethodId));
      toast.success('card deleted successfully');
    } catch (err) {
      console.log(err);
      toast.error('Sorry, the card could not be deleted');
    }
  };

  return (
    <div className=" mt-10 p-8 space-y-12 rounded shadow-md w-[950px] bg-white">
      <div className="text-xl font-bold w-full border border-transparent border-b-gray-200 space-x-2 flex justify-left items-center">
        <MdOutlinePayment />
        <span>Payment Cards</span>
      </div>
      <div className="flex space-x-10">
        <div className="w-1/2 p-3 shadow-md h-56">
          <form onSubmit={handleSaveCard}>
            <div className="text-md font-bold w-full bg-gray-50 p-2 mb-12">New card</div>

            <CardElement />
            <div className="w-full flex justify-end">
              <button
                className="primary-btn mx-0 mt-12"
                type="submit"
                disabled={loading || !stripe}
              >
                {loading ? 'Saving...' : 'Save Card'}
              </button>
            </div>
          </form>
        </div>
        <div className="w-1/2  p-3 shadow-md h-56 overflow-auto bg-white">
          <div className="text-md font-bold bg-gray-50 p-2 mb-6">Saved cards</div>
          {savedCards.length > 0 ? (
            <ul>
              {savedCards.map((card: any) => (
                <li key={card.id}>
                  <div className="flex justify-between group mb-4 border border-gray-100 hover:border-gray-200 shadow-sm hover:shadow-md duration-300 rounded-xl py-3">
                    <div className="flex items-center space-x-4 px-2">
                      <span className="text-gray-300 group-hover:text-defaultText font-semibold">
                        {card.cardBrand.charAt(0).toUpperCase() + card.cardBrand.slice(1)}
                      </span>
                      <span className="text-gray-500">•••• {card.last4}</span>
                    </div>
                    <div className="space-x-4 items-center justify-center flex px-2">
                      <span className="text-gray-500 text-xs">
                        {card.isDefault ? (
                          '[ default ]'
                        ) : (
                          <button onClick={() => handleSetDefault(card.paymentMethodId)}>
                            Set as default
                          </button>
                        )}
                      </span>
                      <button
                        className="rounded-full px-1 py-1 border border-gray-300 hover:border-deehiy items-center justify-center cursor-pointer hover:text-deehiy text-gray-400"
                        onClick={() => handleDeleteCard(card.paymentMethodId)}
                      >
                        <BsTrash />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <span className="text-gray-400">You haven't saved any cards yet.</span>
          )}
        </div>
      </div>

      <img src="/images/stripe.png" className="w-full sm:w-1/2  mt-24 mx-auto " />
    </div>
  );
};
const PaymentCard = () => {
  return (
    <Elements stripe={stripePromise}>
      <SaveCardForm />
    </Elements>
  );
};

export default PaymentCard;
