import React, { useState } from 'react';
import { buyLead } from '../../../services/paymentService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LeadPurchaseButton = ({ huntId, leadPrice, isPurchased }: any) => {
  const [loading, setLoading] = useState(false);
  const [isAlreadyPurchased, setIsAlreadyPurchased] = useState(isPurchased);

  const handlePurchase = async () => {
    setLoading(true);
    try {
      const response = await buyLead({
        huntId,
      });
      setIsAlreadyPurchased(true);
      toast.success('Lead purchased successfully!');
      console.log(response.data);
    } catch (error: any) {
      setIsAlreadyPurchased(false);
      console.error('Error purchasing lead:', error.response?.data || error.message);
      toast.error(error.response?.data || error.message);
    }
    setLoading(false);
  };

  if (isAlreadyPurchased) {
    return (
      <button
        className="secondary-btn my-1"
        onClick={() => window.open(`/users/leads/hunt/${huntId}`, '_blank')}
      >
        Contact your Lead
      </button>
    );
  }

  return (
    <div className="my-1">
      <button
        className="primary-btn group duration-150"
        onClick={handlePurchase}
        disabled={loading}
      >
        {loading ? (
          'Processing...'
        ) : (
          <div className="justify-center group-hover:scale-105 flex items-center space-x-2">
            <span className="whitespace-nowrap">$ {leadPrice}</span>
            <span>Purchase Lead</span>
          </div>
        )}
      </button>
    </div>
  );
};

export default LeadPurchaseButton;
