import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobal } from './contexts/global';
import { useChat } from './contexts/chat';
import { ToastContainer, Slide } from 'react-toastify';

import { initializeFCMToken } from './utils/firebase';

import Router from './routes/Router';
import Favicon from './utils/favicon';
import { HelmetProvider } from 'react-helmet-async';
import socket from './utils/socket';
import PopUp from './components/main/Popup';
import { useAuth } from '../src/contexts/auth';
import jwtDecode from 'jwt-decode';
// import TagManager from 'react-gtm-module';

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_ID,
//   dataLayer: {
//     userId: 'pierre',
//     userProject: 'deehiy App',
//   },
// };
//@ts-ignore
// TagManager.initialize(tagManagerArgs);

export interface SocketResponse {
  success: string;
  data: any;
}

// interface clientId {
//   data: string;
// }

function App() {
  const initialRef = {
    id: '',
    profilePic: '',
    name: '',
    lastMessage: '',
    groupId: '',
    messageType: '',
    fileName: '',
    extension: '',
    albumName: '',
    contactName: '',
    messageSubType: '',
    note: '',
  };
  const location = useLocation();
  const { messageCount, setMessageCount } = useChat();
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const popUpDataRef = useRef(initialRef);
  const { modalRootRef } = useGlobal();

  const closePopUp = () => {
    setIsPopUpOpen(false);
    popUpDataRef.current = initialRef;
  };
  const token = localStorage.getItem('@App:token') || '';
  const userDetails: any = token ? jwtDecode(token) : {};
  const { id: loginUserId = '' } = userDetails;

  const { currentUser } = useAuth();
  const { id = '' } = currentUser || {};

  const getUnreadMessageCount = (userId: number) => {
    socket.emit('get_unread_message_count_new', { userId }, (ackData: SocketResponse) => {
      // socket.emit('get_unread_message_count', { userId }, (ackData: SocketResponse) => {
      const { data } = ackData;
      setMessageCount(data);
    });
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isPopUpOpen) {
      timeoutId = setTimeout(() => {
        setIsPopUpOpen(false);
        popUpDataRef.current = initialRef;
      }, 6000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isPopUpOpen]);

  useEffect(() => {
    if (id) {
      const intervalId = setInterval(() => {
        getUnreadMessageCount(id);
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      initializeFCMToken();
    }
  }, [id]);

  useEffect(() => {
    if (loginUserId && id === loginUserId) {
      socket.emit('connect_user', { userId: id }, (connectUserData: SocketResponse) => {
        console.log(connectUserData.success.toLowerCase(), id);
        if (connectUserData.success.toLowerCase() === 'user connected') {
          socket.on('receive_message', (receivedMessageData: SocketResponse) => {
            console.log('Received new messages', receivedMessageData?.data?.message);
            const groupId = receivedMessageData?.data?.groupId;
            if (receivedMessageData?.data?.message === 'InitiatedChatFirstTIme@#') {
              getUnreadMessageCount(id);
              return null;
            }
            if (popUpDataRef.current.id === groupId) {
              popUpDataRef.current.lastMessage += `\n${receivedMessageData.data.message}`;
            } else {
              popUpDataRef.current = {
                id: groupId,
                profilePic: receivedMessageData.data.user.image || '',
                name: `${receivedMessageData.data.user.firstName} 
                ${receivedMessageData.data.user.lastName ?? ''}`,
                lastMessage: receivedMessageData.data.message,
                messageType: receivedMessageData.data.messageType,
                fileName: receivedMessageData.data.fileName,
                extension: receivedMessageData.data.extension,
                groupId: groupId,
                albumName: receivedMessageData.data?.album?.name,
                contactName: receivedMessageData.data.sharedContacts?.[0]?.user
                  ? `${receivedMessageData.data.sharedContacts[0].user.firstName} 
                  ${receivedMessageData.data.sharedContacts[0].user.lastName ?? ''}`
                  : '',
                messageSubType: receivedMessageData.data.messageSubType,
                note: receivedMessageData?.data?.note?.note,
              };
            }
            setIsPopUpOpen(true);
            getUnreadMessageCount(id);
          });
          socket.on('message_status_received', (messageStatus: any) => {
            console.log('messageStatus', messageStatus);
          });
          socket.on('seen_all_messages_received', (seenAllMessages: any) => {
            console.log('seenAllMessages', seenAllMessages);
          });
          // socket.on('new_notification', (notification) => {
          //   alert('new notific from app.tsx');
          //   console.log(notification);
          // });
        }
      });
    }
    return () => {
      if (token === '') {
        setMessageCount(0);
      }
      socket.off('receive_message');
      socket.off('message_status_received');
      socket.off('seen_all_messages_received');
    };
  }, [id, loginUserId, location.pathname]);

  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <HelmetProvider>
      {isPopUpOpen && <PopUp onClose={closePopUp} popUpData={popUpDataRef.current} />}
      <Favicon messageCount={messageCount} />
      <Router />
      <div ref={modalRootRef}></div>
      <ToastContainer transition={Slide} />
    </HelmetProvider>
  );
}

export default App;
