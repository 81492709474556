import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdminLabel from './AdminLabel';

const AdminHuntingboard = () => {
  const navigate = useNavigate();

  return (
    <>
      <AdminLabel />
      <div className=" p-4 w-96 flex mx-auto pt-20 justify-between ">
        <button onClick={() => navigate('/admin/hunts')} className=" h-12 w-36 primary-btn">
          Hunts
        </button>
        <button onClick={() => navigate('/admin/matches')} className=" h-12 w-36 primary-btn">
          Matches
        </button>
      </div>
    </>
  );
};

export default AdminHuntingboard;
