import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import socket from '../utils/socket';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { baseUrl } from '../utils/helper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showToast = _.throttle((message) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 1000,
  });
}, 8000);

const Axios = axios.create({
  baseURL: baseUrl,
});

Axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('@App:token');

  config.headers['x-auth-token'] = token;
  return config;
});

Axios.interceptors.response.use(null, (error) => {
  const token = localStorage.getItem('@App:token');
  const userDetails: any = token ? jwtDecode(token) : {};
  const { id: loginUserId = '' } = userDetails;

  const expectedError =
    error.response && error.response.status >= 400 && error.response.status < 500;

  if (!expectedError) {
    console.log('Logging the error', error);
    // alert(`An unexpected error occurred: ${error}`);
    showToast('An unexpected error occurred. Please try again.');
  }
  if (
    error.response &&
    error.response.status === 400 &&
    error.response.data.toLowerCase().includes('invalid token')
  ) {
    socket.emit(
      'logout',
      { userId: loginUserId, token: localStorage.getItem('@App:token') },
      (logoutAck: any) => {
        console.log('logoutAck', logoutAck);
      }
    );

    socket.emit('disconnect_user', { userId: loginUserId }, (disconnectUserAck: any) => {
      console.log('disconnectUserAck', disconnectUserAck);
      socket.disconnect();
    });
    localStorage.removeItem('@App:user');
    localStorage.removeItem('@App:token');
    setAuthToken(false);

    window.location.href = '/login';
  }

  return Promise.reject(error);
});

export default {
  get: Axios.get,
  post: Axios.post,
  put: Axios.put,
  delete: Axios.delete,
  patch: Axios.patch,
};
