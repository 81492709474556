import { useState, useEffect } from 'react';
import { adaptStatus } from '../../../../utils/adaptStatusStyle';
import {
  adminSendStatusEmail,
  adminChangePropertyPublishStatus,
} from '../../../../services/adminService';
import { useProperty } from '../../../../contexts/property';
import { Status } from '../../../../types/types';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APPROVED, DELETED, PENDING, REJECTED } from '../../../../constants/constant';

interface Props {
  modifyProperty: any;
}

const UpdateStatusContent: React.FC<Props> = () => {
  const { currentProperty, setCurrentProperty } = useProperty();

  const [updatedStatus, setUpdatedStatus] = useState(currentProperty.status);
  const [message, setMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [willSendEmail, setWillSendEmail] = useState(false);

  const handleUpdateStatus = () => {
    if (updatedStatus !== currentProperty.status) {
      if (updatedStatus !== 'approved') {
        setCurrentProperty({ ...currentProperty, status: updatedStatus, published: false });
      } else {
        setCurrentProperty({ ...currentProperty, status: updatedStatus, published: true });
      }
    } else {
      toast.warn('the status has not changed');
      return;
    }
  };

  useEffect(() => {
    const updatePropertyStatus = async () => {
      if (currentProperty.propertyId) {
        // Update the publish status
        await adminChangePropertyPublishStatus(currentProperty.propertyId, updatedStatus);
        toast.info('property up to date', { autoClose: 700 });

        // Optionally send an email
        if (willSendEmail) {
          await adminSendStatusEmail(currentProperty.propertyId, updatedStatus, message);
          toast.info('email sent');
        }

        // Reset states
        setMessage('');
        setWillSendEmail(false);
      }
    };

    updatePropertyStatus();
  }, [currentProperty.status]);

  // Update the isDisabled state based on the updatedStatus and currentProperty.status
  useEffect(() => {
    setIsDisabled(updatedStatus === currentProperty.status);
  }, [updatedStatus, currentProperty.status]);

  return (
    <div className="p-4 w-4/5">
      <div className="text-sm mb-6 w-1/3 grid grid-cols-2">
        <span>current status:</span>
        <span className="justify-end flex w-14">{adaptStatus(currentProperty.status)}</span>
      </div>
      <div className="text-sm mb-6 w-1/3 grid grid-cols-2 ">
        <span className="pt-1">new status:</span>

        <div className="flex justify-start">
          <select
            id="status"
            name="status"
            value={updatedStatus}
            className="h-7 py-0 text-sm text-center font-normal flex items-center justify-center ring-deehiy focus:border-deehiy rounded-md focus:outline-none focus:ring-0"
            onChange={(e) => {
              setUpdatedStatus(e.target.value as Status);
            }}
          >
            <option value={APPROVED}>approved</option>
            <option value={REJECTED}>rejected</option>
            <option value={PENDING}>pending approval</option>
            <option value={DELETED}>deleted</option>
            {/* <option value={INPROGRESS}>in progress</option> */}
          </select>
        </div>
      </div>
      <div className="mb-4">
        <div>
          <input
            type="checkbox"
            id="send-email"
            name="send-email"
            checked={willSendEmail}
            className="text-deehiy rounded active:scale-90 duration-200 focus:ring-0"
            onChange={(e) => {
              setWillSendEmail(e.target.checked);
            }}
          />
          <label htmlFor="send-email" className="ml-2">
            Send an email to the user.
          </label>
        </div>

        {willSendEmail && (
          <>
            <label htmlFor="message" className="block font-small text-gray-500 mb-2 mt-6">
              Message
            </label>
            <textarea
              id="message"
              className="ring-deehiy focus:ring-0 focus:border-deehiy w-full px-3 py-2 rounded border-gray-300 focus:outline-none"
              rows={2}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            ></textarea>
          </>
        )}
      </div>
      <div className="flex flex-col justify-between items-center mt-12">
        {updatedStatus !== 'approved' && (
          <p className="text-red-400 text-sm">this status will unpublish the property</p>
        )}
        <button
          className={`${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} primary-btn`}
          onClick={handleUpdateStatus}
          disabled={isDisabled}
        >
          update status
        </button>
      </div>
    </div>
  );
};

export default UpdateStatusContent;
