import React, { useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { Dropdown } from 'flowbite-react';
import Share from '../main/Share';
import { adjustImageSize } from '../../utils/adjustImageSize';
import GalleryTemplate from './GalleryTemplate';
import GalleryPortal from './GalleryPortal';
// import { ReactComponent as LoveSVG } from '../../components/assets/listing/love.svg';
import { ReactComponent as ShareSVG } from '../../components/assets/listing/share.svg';
import { ReactComponent as HomeSVG } from '../../components/assets/listing/home.svg';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';

const MobileSlideshow = ({
  list,
  isLoved,
  loveProperty,
  loveCount,
  propertyId,
  propertyTitle,
  isWL,
  businessId,
}: any) => {
  const navigate = useNavigate();
  // State to store the index of the clicked item
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleClick = (index: number) => {
    // Update the selected index state
    setSelectedIndex(index);
    // Show the GalleryPortal
    setShowModal(true);
  };

  const handleBackHome = () => {
    const previousUrl = document.referrer;
    const url = `${process.env.REACT_APP_BASE_URL_PROD}`;
    // Check if the referrer is from deehy.com
    if (isWL && businessId) {
      navigate(`/business/${businessId}/market`);
    } else if (previousUrl.includes(url)) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <section className="py-0 mx-auto px-0">
      <div className="relative h-0">
        <div className="absolute w-full z-10 top-4 flex justify-between px-4">
          <div>
            <BsChevronLeft
              className="hidden p-2 text-defaultText w-8 h-8 bg-white rounded-full"
              onClick={() => navigate(-1)}
            />
            <button onClick={handleBackHome} className="shadow-xl flex items-center text-deehiy">
              <HomeSVG className={`w-7 h-7 text-defaultText`} />
            </button>
          </div>
          <div className="flex justify-between gap-x-5">
            <Dropdown
              arrowIcon={false}
              inline={true}
              placement="bottom-end"
              label={
                <div className="shadow-xl  flex items-center text-deehiy">
                  {/* <BsFillShareFill className="my-auto w-4 h-4 sm:w-4 sm:h-4" /> */}
                  {/* <ItemIcon size={5} Icon={shareSVG} /> */}

                  <ShareSVG className={`w-7 h-7 text-defaultText`} />
                </div>
              }
            >
              <Dropdown.Header>
                <Share
                  type={`${isWL ? 'wl-properties' : 'properties'}`}
                  id={propertyId}
                  propertyTitle={propertyTitle}
                />
              </Dropdown.Header>
            </Dropdown>
            <div className=" w-8 h-8 bg-white rounded-full flex items-center justify-center">
              {isLoved ? (
                <AiFillHeart
                  onClick={loveProperty}
                  className={`w-6 h-6 text-deehiy-red duration-200 cursor-pointer`}
                />
              ) : (
                <AiOutlineHeart
                  onClick={loveProperty}
                  className={`w-6 h-6 text-defaultText opacity-60
                duration-200 cursor-pointer`}
                />
              )}
            </div>
            {loveCount > 0 && loveCount}
          </div>
        </div>
      </div>
      <Slide
        slidesToScroll={1}
        slidesToShow={1}
        indicators={false}
        arrows={false}
        canSwipe={true}
        transitionDuration={200}
        autoplay={false}
        responsive={[
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: -300,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {list.map((el: any, i: number) => (
          <div
            key={i}
            onClick={() => handleClick(i)}
            className="flex   justify-center items-center mx-0"
          >
            <div className="bg-[#f7f7f7] relative hover:cursor-pointer w-full aspect-video overflow-hidden">
              <img
                src={el.url && adjustImageSize(el.url, 400)}
                alt="prop"
                className="w-full object-cover "
              />
              <div className="absolute bottom-0 right-0 mb-2 mr-2 bg-deehiy bg-opacity-60 text-white text-xs p-1">
                {i + 1}/{list.length}
              </div>
            </div>
          </div>
        ))}
      </Slide>

      <GalleryPortal setShowModal={setShowModal} showModal={showModal}>
        <GalleryTemplate images={list} defaultIndex={selectedIndex} />
      </GalleryPortal>
    </section>
  );
};

export default MobileSlideshow;
