import React, { useMemo, useRef } from 'react';
import { Grid, _ } from 'gridjs-react';
import { useNavigate } from 'react-router-dom';

import 'gridjs/dist/theme/mermaid.css';

import AdminLabel from './AdminLabel';

const AdminInteractions = () => {
  const token = localStorage.getItem('@App:token');
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        id: '0',
        name: 'source',
        width: '5%',
        formatter: (cell: any) => _(<div className="px-3 text-center">{cell}</div>),
      },
      {
        id: '1',
        name: 'type',
        sort: 'true',
        align: 'left',
        width: '10%',
        formatter: (cell: any) => _(<div className="px-3 text-center">{cell}</div>),
      },
      {
        id: '2',
        sort: 'true',
        align: 'left',
        name: 'device',
        width: '5%',
        formatter: (cell: any) => _(<div className="px-3 text-center">{cell}</div>),
      },
      {
        id: '3',
        sort: 'true',
        align: 'left',
        name: 'section',
        width: '5%',
        formatter: (cell: any) => _(<div className="px-3 text-center">{cell}</div>),
      },
      {
        id: '4',
        sort: 'true',
        align: 'left',
        name: 'contacted user',
        width: '5%',
        formatter: (cell: any) =>
          _(
            <div
              className="px-3 text-center cursor-pointer hover:font-bold"
              onClick={() => navigate(`/member/${cell}`)}
            >
              {cell}
            </div>
          ),
      },
      {
        id: '5',
        sort: 'true',
        align: 'left',
        name: 'contacter',
        width: '5%',
        formatter: (cell: any) =>
          _(
            <div
              className="px-3 text-center cursor-pointer hover:font-bold"
              onClick={() => navigate(`/member/${cell}`)}
            >
              {cell}
            </div>
          ),
      },
      {
        id: '6',
        align: 'left',
        name: 'business ID',
        width: '5%',
        formatter: (cell: any) =>
          _(
            <div
              className="px-3 text-center cursor-pointer hover:font-bold"
              onClick={() => navigate(`/business/${cell}`)}
            >
              {cell}
            </div>
          ),
      },
      {
        id: '7',
        name: 'date',
        width: '8%',
        sort: true,
        formatter: (cell: any) =>
          _(<div className="px-3 text-center">{new Date(cell).toLocaleDateString()}</div>),
      },
    ];
  }, []);

  return (
    <>
      <AdminLabel />
      <Grid
        ref={gridRef}
        className={{
          table: 'text-xs w-full bg-gray-300',
        }}
        server={{
          url: '/api/admin/interactions',
          headers: { 'x-auth-token': `${token}` },
          then: (data: any) => {
            console.log('data fetched', data);
            return data.interactions.map((interactions: any) => [
              interactions.sourcePage,
              interactions.buttonType,
              interactions.deviceType,
              interactions.section,
              interactions.contactedUserId,
              interactions.userId,
              interactions.BusinessId,
              interactions.createdAt,
            ]);
          },
          handle: (res: any) => {
            console.log(res);
            // no matching records found
            if (res.status === 500) {
              const data = res.json();
              alert(data.message);
              return { data: [] };
            }

            if (res.status === 404) return { data: [] };
            if (res.ok) return res.json();

            throw Error('oh no :(');
          },
          total: (data: any) => data.total,
        }}
        columns={columns}
        sort={{
          multiColumn: false,
          server: {
            url: (prev, columnsArray) => {
              if (!columnsArray.length) return prev;

              const col = columnsArray[0];
              const dir = col.direction === 1 ? 'asc' : 'desc';
              let colName = [
                'sourcePage',
                'type',
                'device',
                'section',
                'contacted user',
                'contacter',
                'Business ID',
                'date',
              ][col.index];

              return `${prev}?order=${colName}&dir=${dir}`;
            },
          },
        }}
        pagination={{
          server: {
            url: (prev: string, page: number) =>
              `${prev}${prev.includes('?') ? '&' : '?'}page=${page + 1}`,
          },
        }}
      />
    </>
  );
};

export default AdminInteractions;
