import React, { useEffect, useState } from 'react';
import PhoneVerification from '../../components/dashboard/PhoneVerification';
import PhoneInput from 'react-phone-input-2';
import { update } from '../../services/auth/userService';
import Loader from '../../components/main/Loader';

const HuntPhoneVerification = ({ phone, isPhoneVerified, onVerified }: any) => {
  console.log(phone);
  console.log(isPhoneVerified);
  console.log(onVerified);
  const [newPhone, setNewPhone] = useState(phone);
  const [isSaved, setIsSaved] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handlePhoneNumberChange = (ph: string) => {
    setIsSaved(false);

    setNewPhone(ph);
    setMessage('');
  };

  const save = async () => {
    if (newPhone && !isSaved) {
      try {
        await update({ phone: newPhone, isPhoneVerified: false });

        setIsSaved(true);
      } catch (err) {
        setIsSaved(false);
        setMessage('something went wrong');
      } finally {
        setIsLoading(false);
      }
    }
  };

  //handle update phone
  useEffect(() => {
    return () => {
      // update user
    };
  }, []);

  return (
    <div className="flex flex-col space-y-8 w-full sm:w-[600px] py-8 px-14">
      <div>
        <div className="text-xl font-bold  text-center">Verify your phone number</div>
        <div className="text-base text-gray-600 py-3">
          Verify your phone number to publish your hunt. This keeps our community transparent and
          lets agents reach you. Prefer privacy? Update your profile settings.
        </div>
      </div>
      <div className="relative  text-center py-2">
        <div className="w-72 mx-auto py-6">
          <PhoneInput
            country={'th'}
            enableSearch={true}
            countryCodeEditable={false}
            placeholder={'Enter phone number'}
            value={newPhone}
            onChange={handlePhoneNumberChange}
            buttonClass={'hover:!p-0 !border-none'}
            inputClass={
              '!w-full !text-xl !py-5 !ring-2 ring-gray-400 !border-none focus:!ring-deehiy '
            }
            searchClass={'focus:ring-deehiy '}
          />
        </div>
        {!isSaved ? (
          <>
            {newPhone && newPhone.length > 6 && (
              <button disabled={isLoading} className="mt-2 primary-btn rounded" onClick={save}>
                {isLoading ? <Loader /> : 'Save'}
              </button>
            )}
          </>
        ) : (
          <div className="w-full">
            <PhoneVerification
              phone={newPhone}
              isPhoneVerified={isPhoneVerified}
              isSaved={isSaved}
              onVerified={onVerified}
            />
          </div>
        )}
        {message}
      </div>
    </div>
  );
};

export default HuntPhoneVerification;
