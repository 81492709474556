import React from 'react';
import { GoStarFill, GoStar } from 'react-icons/go';

const StarRating = ({ stars }: { stars: number }) => {
  // Ensure the stars argument is between 0 and 5
  const clampedStars = Math.min(Math.max(stars, 0), 5);

  return (
    <div className="flex space-x-1">
      {Array.from({ length: 5 }).map((_, index) =>
        index < clampedStars ? (
          <GoStarFill key={index} className="text-yellow-300 text-xl" />
        ) : (
          <GoStar key={index} className="text-gray-300 text-xl" />
        )
      )}
    </div>
  );
};

export default StarRating;
