import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  // sortingFns,
} from '@tanstack/react-table';
import {
  rankItem,
  // RankingInfo,
  // compareItems,
} from '@tanstack/match-sorter-utils';

import type {
  ColumnDef,
  SortingState,
  FilterFn,
  // SortingFn,
} from '@tanstack/react-table';

import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { BsChevronExpand } from 'react-icons/bs';
import { adminGetAllHunts } from '../../../services/adminService';
import AdminLabel from './AdminLabel';
import { Hunt } from '../../../types/types';
import { formatDate } from '../../../utils/dateFormatter';
import DebouncedInput from './DebouncedInput';

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const columnHelper = createColumnHelper<Hunt>();

const AdminHunts = () => {
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<Hunt[]>(() => []);
  const [globalFilter, setGlobalFilter] = useState('');

  const columns = useMemo<ColumnDef<Hunt, any>[]>(
    () => [
      columnHelper.accessor('huntId', {
        id: 'huntId',
        cell: (info) => (
          <div
            title="edit hunt"
            onClick={() => navigate(`/admin/edit-hunt/${info.renderValue()}`)}
            className="p-4 cursor-pointer hover:text-deehiy text-base semibold hover:underline rounded"
          >
            {info.renderValue()}
          </div>
        ),
        // filterFn: 'fuzzy',
        header: () => <span className="pl-4">hunt ID</span>,
      }),
      columnHelper.accessor('userId', {
        id: 'user ID',
        cell: (info) => (
          <div
            className="hover:font-bold hover:underline cursor-pointer"
            onClick={() => window.open(`/member/${info.renderValue()}`, '_blank')}
          >
            {info.renderValue()}
          </div>
        ),
        // filterFn: 'fuzzy',
        header: () => <span className="pl-4">user ID</span>,
      }),
      columnHelper.accessor('matches', {
        id: 'matches',
        cell: (info) => (
          <div className="bg-green-100 group text-center relative cursor-pointer">
            {info.getValue().length}
            <div className=" flex flex-col overflow-auto bg-white z-10 shadow-xl absolute invisible group-hover:visible">
              {info.getValue().length > 0 &&
                info.getValue().map((m: any) => {
                  return (
                    <div className="text-black flex flex-row gap-3 p-3" key={m.id}>
                      <div>{m.id}</div>
                      <div>{m.isSubmitted ? 'submitted' : 'not submitted'}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        ),
        // filterFn: 'fuzzy',
        header: () => <span className="  pl-4">Matches</span>,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        // filterFn: 'fuzzy',
        header: () => <span className="pl-4">status</span>,

        cell: (info) => {
          const status = info.getValue();
          let bg =
            status === 'active' ? 'bg-blue-400 font-bold text-white rounded p-1' : 'bg-red-200';
          return <div className={`${bg} w-full text-center`}>{status}</div>;
        },
      }),

      columnHelper.accessor('leadPrice', {
        header: 'deehiy Price $',
        cell: (info) => (
          <div className="w-full bg-yellow-50 text-center">
            <span>{info.renderValue()}</span>
          </div>
        ),
      }),
      columnHelper.accessor('isFree', {
        header: 'Free',
        cell: (info) => {
          const isFree = info.getValue(); // Get the current value of 'isFree'

          return <div className="w-full text-center">{isFree ? '✅' : '-'}</div>;
        },
      }),
      columnHelper.accessor('approvalStatus', {
        header: 'approved',
        cell: (info) => {
          const status = info.getValue();
          let bg =
            status === 'approved' ? 'bg-green-400 font-bold text-white rounded p-1' : 'bg-gray-200';
          return <div className={`${bg} w-full text-center`}>{status}</div>;
        },
      }),
      columnHelper.accessor('location', {
        header: () => <span className="pl-8">location</span>,
        cell: (info) => (
          <span title={info.renderValue()} className=" pl-4 w-44 line-clamp-1">
            {info.renderValue()}
          </span>
        ),
      }),
      columnHelper.accessor('isoCodeCountry', {
        header: () => <span className="pl-4">country</span>,
        cell: (info) => <span>{info.renderValue()}</span>,
      }),
      columnHelper.accessor('areaRadius', {
        header: 'area radius',
        cell: (info) => <span>{info.renderValue()}km</span>,
      }),
      columnHelper.accessor('propertyType', {
        header: 'property type',
        cell: (info) => <span>{info.renderValue()}</span>,
      }),
      // columnHelper.accessor('homeSize', {
      //   id: 'homeSize',
      //   header: 'home size',
      //   cell: (info) => <span>{info.renderValue()}</span>,
      // }),
      columnHelper.accessor('bedrooms', {
        id: 'bedrooms',
        header: 'bedrooms',
        cell: (info) => <span>{info.renderValue()}</span>,
      }),
      columnHelper.accessor('price', {
        id: 'price',
        header: 'budget',
        cell: (info) => <span>{info.renderValue()}</span>,
      }),
      columnHelper.accessor('features', {
        id: 'features',
        cell: (info) => (
          <div className=" font-bold group text-center relative cursor-pointer">
            {info.getValue().length}
            <div className=" flex flex-col overflow-auto bg-white z-10 shadow-xl absolute invisible group-hover:visible">
              {info.getValue().length > 0 &&
                info.getValue().map((f: any) => {
                  return (
                    <div
                      key={f.featureTitle}
                      className="text-black font-normal flex flex-row gap-3 px-3 py-1 w-56"
                    >
                      {f.featureTitle}
                    </div>
                  );
                })}
            </div>
          </div>
        ),
        // filterFn: 'fuzzy',
        header: () => <span className="pl-4">features</span>,
      }),
      columnHelper.accessor('createdAt', {
        header: 'created at',
        cell: (info) => <span>{formatDate(info.renderValue())}</span>,
      }),
      columnHelper.accessor('updatedAt', {
        header: 'updated at',
        cell: (info) => <span>{formatDate(info.renderValue())}</span>,
      }),
    ],
    []
  );

  const table = useReactTable<any>({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    adminGetAllHunts()
      .then((res: any) => {
        console.log(res.data);
        setData(() => [...res.data]);
      })
      .catch((error: any) => {
        console.error('Error fetching the hunts:', error);
        alert('An error occurred while fetching the hunts');
      });
  }, []);

  return (
    <div>
      <AdminLabel />
      <div className="py-8 font-bold">
        Number of Hunts:<span className="text-deehiy px-1">{data.length}</span>
      </div>
      <div>
        <DebouncedInput
          debounce={300}
          value={globalFilter ?? ''}
          onChange={(value) => setGlobalFilter(String(value))}
          className="p-2 font-lg rounded-md focus:rounded-lg shadow-sm focus:shadow-lg duration-500 border outline-none focus:border-deehiy"
          placeholder="Search all columns..."
        />
      </div>
      <div className="h-2" />
      <div className="p-2">
        <table className="w-full ">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex items-center text-xs'
                            : 'hidden',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <BiChevronUp className="w-5 h-5" />,
                          desc: <BiChevronDown className="w-5 h-5" />,
                        }[header.column.getIsSorted() as string] ?? (
                          <BsChevronExpand className="ml-1 w-4 h-4 text-gray-300" />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    className=" bg-white text-gray-700 text-xs border-b border-gray-300"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        {/* pagination */}
        <div className="h-2" />
        <div className="flex items-center gap-2">
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
          <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="border border-gray-400 p-1 rounded w-12 focus:ring-deehiy focus:border-none"
            />
          </span>
          <select
            className="border border-gray-400 p-1 rounded text-center focus:ring-deehiy focus:border-none"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div>{table.getPrePaginationRowModel().rows.length} Rows</div>
      </div>
    </div>
  );
};

export default AdminHunts;
