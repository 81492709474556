import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getHuntByIdService, updateHuntService } from '../../../services/adminService';
import Loader from '../../main/Loader';
import {
  CONDO,
  HOUSE,
  LAND,
  TOWNHOUSE,
  APPROVED,
  DELETED,
  PENDING,
  REJECTED,
} from '../../../constants/constant';
// import { useHunt } from '../../contexts/hunt';
import { countries } from '../../../data/data';
import { SelectMenuOption } from '../../form/countries/Selector';
import LocationSearch from '../../steps/propertySteps/Location/LocationSearch';
import { Hunt } from '../../../types/types';
import { NumericFormat } from 'react-number-format';

const AdminEditHunt = () => {
  const { id } = useParams<{ id: string }>(); // Ensure `id` is typed
  const navigate = useNavigate();
  const [hunt, setHunt] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState('');

  const [canSave, setCanSave] = useState(false);
  const [locationObject, setLocationObject] = useState({
    city: '',
    country: '',
    latitude: 0,
    longitude: 0,
    postalCode: '',
  });
  const [locationString, setLocationString] = useState('');

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setHunt((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const handleSave = async () => {
    const isConfirmed = window.confirm('Are you sure you want to edit this hunt?');
    if (!isConfirmed) return;

    setIsLoading(true);
    setMessage('---');
    try {
      await updateHuntService({ huntId: Number(id), ...hunt }); // Pass `id` and data
      setMessage('Success -- hunt updated');
    } catch (error) {
      console.error('Failed to update hunt details', error);
      setMessage('Error -- hunt not saved');
    } finally {
      setIsLoading(false);
    }
  };

  const requiredFieldsByType = [
    {
      propertyType: HOUSE,
      areaRadius: true,
      price: true,
      bedrooms: true,
      homeSize: false,
      lotSize: false,
      location: true,
      postalCode: false,
    },
    {
      propertyType: TOWNHOUSE,
      areaRadius: true,
      price: true,
      bedrooms: true,
      homeSize: false,
      lotSize: false,
      location: true,
      postalCode: false,
    },
    {
      propertyType: CONDO,
      areaRadius: true,
      price: true,
      bedrooms: true,
      homeSize: false,
      lotSize: false,
      location: true,
      postalCode: false,
    },
    {
      propertyType: LAND,
      areaRadius: true,
      price: true,
      bedrooms: false,
      homeSize: false,
      lotSize: true,
      location: true,
      postalCode: false,
    },
  ];

  const findIsoCodeCurrency = (selectedCountry: string) => {
    const code = countries.find(
      (option: any) => option.value === selectedCountry
    ) as SelectMenuOption;
    if (!code) return 'THB';

    return code.currency;
  };

  const handlePriceChange = (e: any) => {
    let value = e.target.value; // string
    const formatted = Number(value.replace(/ /g, ''));
    setHunt({ ...hunt, price: formatted });
  };
  const handleLeadPriceChange = (e: any) => {
    let value = e.target.value; // string
    const formatted = Number(value.replace(/ /g, ''));
    setHunt({ ...hunt, leadPrice: formatted });
  };

  const checkRequiredFields = (edditedHunt: Hunt) => {
    const requiredFields: any = requiredFieldsByType.find(
      (fields) => fields.propertyType === edditedHunt.propertyType
    );
    // console.log('current hunt:', currentHunt);
    if (requiredFields) {
      const requiredProperties = Object.keys(requiredFields).filter(
        (key) => key !== 'propertyType' && requiredFields[key]
      );

      for (const property of requiredProperties) {
        // console.log(property, hunt[property]);
        if (edditedHunt[property] === '' || edditedHunt[property] === null) {
          return false;
        }
      }
      if (edditedHunt.price === 0) return false;

      return true;
    }

    return false;
  };

  const handleRadioChange = (event: any) => {
    const { value } = event.target;
    if (value === 'buy') {
      setHunt({ ...hunt, isForSale: true, isForRent: false });
    } else if (value === 'rent') {
      setHunt({ ...hunt, isForRent: true, isForSale: false });
    }
  };
  const handleStatusRadioChange = (event: any) => {
    const { value } = event.target;
    if (value === 'active') {
      setHunt({ ...hunt, status: 'active' });
    } else {
      setHunt({ ...hunt, status: 'closed' });
    }
  };
  const handleFreeRadioChange = (event: any) => {
    const { value } = event.target;
    if (value === 'true') {
      setHunt({ ...hunt, isFree: true });
    } else {
      setHunt({ ...hunt, isFree: false });
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      setIsLoading(true);
      try {
        const res = await getHuntByIdService(Number(id));
        console.log('HUNT', res.data);
        setHunt({ ...res.data, sendEmail: true, sendEmailToAgents: true });
        setLocationString(res.data.location);
      } catch (error) {
        console.error('Failed to fetch hunt details', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDetails();
  }, [id]);

  useEffect(() => {
    console.log('current hunt', hunt);
    if (hunt) {
      if (checkRequiredFields(hunt)) {
        setCanSave(true);
      } else {
        setCanSave(false);
      }
    }
  }, [hunt]);

  useEffect(() => {
    if (!hunt?.propertyType) return;

    if (hunt.propertyType === LAND) {
      setHunt({
        ...hunt,
        bedrooms: 0,
        homeSize: 0,
        lotSize: 0,
      });
    }
    if (hunt.propertyType === CONDO) {
      setHunt({ ...hunt, lotSize: 0 });
    }
  }, [hunt?.propertyType]);

  useEffect(() => {
    console.log('location string', locationString);
    console.log('location object', locationObject);

    setHunt({
      ...hunt,
      location: locationString,
      postalCode: locationObject.postalCode ? locationObject.postalCode : '',
      city: locationObject.city,
      isoCodeCurrency: findIsoCodeCurrency(locationObject.country),
      isoCodeCountry: locationObject.country,
      latitude: locationObject.latitude,
      longitude: locationObject.longitude,
    });
  }, [locationObject]);

  if (!hunt) return <p className="text-center mt-4">Loading...</p>;

  return (
    <div className="mx-auto w-[700px] pt-20">
      {isLoading && <Loader />}
      <div className="flex justify-between w-full">
        <div className="text-xl font-bold"> Hunt ID : #{hunt.huntId}</div>
        <div
          onClick={() => window.open(`/member/${hunt.userId}`, '_blank')}
          className="text-base font-bold cursor-pointer hover:text-deehiy hover:underline mt-2"
        >
          {' '}
          user ID : {hunt.userId}
        </div>
      </div>
      <div className=" flex flex-col space-y-3 border border-gray-400 p-6 rounded-xl bg-gray-50">
        <span className="mt-[-6px] font-bold pb-6 text-gray-500">ADMIN</span>
        {/* admin status */}
        <div className="flex justify-start items-center gap-x-3">
          <label className="text-gray-700">Approval status</label>
          <select
            id="approvalStatus"
            name="approvalStatus"
            value={hunt.approvalStatus}
            className="h-7 py-0 text-sm text-center font-normal flex items-center justify-center ring-deehiy focus:border-deehiy rounded-md focus:outline-none focus:ring-0"
            onChange={handleChange}
          >
            <option value={APPROVED}>approved</option>
            <option value={REJECTED}>rejected</option>
            <option value={PENDING}>pending approval</option>
            <option value={DELETED}>deleted</option>
            {/* <option value={INPROGRESS}>in progress</option> */}
          </select>
          <div>
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="checkbox"
                checked={hunt.sendEmail}
                onChange={(e) => setHunt({ ...hunt, sendEmail: e.target.checked })}
                className="form-checkbox h-5 w-5 text-deehiy"
              />
              <span className={`${hunt.sendEmail ? 'text-deehiy' : 'text-gray-700'}`}>
                send email to the hunter
              </span>
            </label>
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="checkbox"
                checked={hunt.sendEmailToAgents}
                onChange={(e) => setHunt({ ...hunt, sendEmailToAgents: e.target.checked })}
                className="form-checkbox h-5 w-5 text-deehiy"
              />
              <span className={`${hunt.sendEmailToAgents ? 'text-deehiy' : 'text-gray-700'}`}>
                ⚠️ send email to the agents
              </span>
            </label>
          </div>
        </div>
        {/* is FREE */}
        <div className="flex gap-4">
          {' '}
          Hunt is Free:
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name="isFree"
              value="true"
              checked={hunt.isFree === true}
              onChange={handleFreeRadioChange}
              className="form-radio text-deehiy focus:ring-2 focus:ring-deehiy"
            />
            <span>Yes</span>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name="isFree"
              value="false"
              checked={hunt.isFree === false}
              onChange={handleFreeRadioChange}
              className="form-radio text-deehiy focus:ring-2 focus:ring-deehiy"
            />
            <span>No</span>
          </label>
        </div>
        {/* Lead price */}
        <div className="flex gap-x-4 items-center justify-start">
          <label className="text-gray-700">
            {/* budget{currentHunt.isForRent && ' / month'} (in {currentHunt.isoCodeCurrency}) */}
            Lead Price $
          </label>
          <NumericFormat
            id="leadPrice"
            name="leadPrice"
            className="bg-yellow-50 w-20 focus:ring-deehiy rounded border-gray-300 focus:border-gray-300"
            onChange={handleLeadPriceChange}
            value={hunt.leadPrice}
            thousandSeparator=" "
            placeholder="0"
            // suffix={currentHunt.isoCodeCurrency || ''}
          />
        </div>
      </div>

      <div className=" flex flex-col border border-deehiy p-6 rounded-xl mt-2 bg-gray-50 space-y-3">
        <span className="mt-[-6px] font-bold pb-6 text-gray-500">HUNT DETAILS</span>
        {/* is Active */}
        <div className="flex gap-4">
          {' '}
          Status:
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name="status"
              value="active"
              checked={hunt.status === 'active'}
              onChange={handleStatusRadioChange}
              className="form-radio text-deehiy focus:ring-2 focus:ring-deehiy"
            />
            <span>Active</span>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name="status"
              value="closed"
              checked={hunt.status === 'closed'}
              onChange={handleStatusRadioChange}
              className="form-radio text-deehiy focus:ring-2 focus:ring-deehiy"
            />
            <span>Closed</span>
          </label>
        </div>
        <div className="flex gap-4">
          Type:
          <label className="flex items-center gap-2">
            <input
              type="radio"
              id="buy"
              name="propertyOption"
              value="buy"
              checked={hunt.isForSale}
              onChange={handleRadioChange}
              className="form-radio text-deehiy focus:ring-2 focus:ring-deehiy"
            />
            <span>Buy</span>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="radio"
              id="rent"
              name="propertyOption"
              value="rent"
              checked={hunt.isForRent}
              onChange={handleRadioChange}
              className="form-radio text-deehiy focus:ring-2 focus:ring-deehiy"
            />
            <span>Rent</span>
          </label>
        </div>

        <div className="flex gap-x-4 items-center justify-center">
          <label className="flex font-normal">Location</label>
          <div className="w-full">
            <div className="bg-white w-full pl-2 flex items-center border border-gray-300 focus:border-deehiy rounded-xl ">
              <LocationSearch
                locationString={locationString}
                setLocationString={setLocationString}
                setLocationObject={setLocationObject}
                label=""
              />
            </div>
            <div className="text-gray-400 text-xs space-x-6">
              <span>lat: {hunt.latitude} -</span>
              <span>long: {hunt.longitude} -</span>
              <span>city: {hunt.city}</span>
              <span>ISO: {hunt.isoCodeCountry}</span>
              <span>ISO: {hunt.isoCodeCurrency}</span>
            </div>
            <div />
          </div>
        </div>
        <div className="grid grid-cols-2">
          {/* property type */}
          <div className="w-52 flex flex-col">
            <label className="text-gray-700">Property type</label>

            <select
              value={hunt.propertyType ? hunt.propertyType : ''}
              className="border border-gray-300 rounded"
              onChange={handleChange}
              name="propertyType"
              id="propertyType"
            >
              <option value="" disabled>
                Select property type
              </option>
              <option value={HOUSE}>{HOUSE}</option>
              <option value={CONDO}>{CONDO}</option>
              <option value={TOWNHOUSE}>{TOWNHOUSE}</option>
              <option value={LAND}>{LAND}</option>
            </select>
          </div>
          {/* areaRadius */}
          <div className="w-28 flex flex-col">
            <label className="text-gray-700">Radius</label>

            <select
              value={hunt.areaRadius ? hunt.areaRadius : ''}
              onChange={handleChange}
              className="border border-gray-300 rounded"
              name="areaRadius"
              id="areaRadius"
            >
              <option value="" disabled>
                {'Define radius'}
              </option>
              <option value="0.5">0.5 km</option>
              <option value="1">1 km</option>
              <option value="1.5">1.5 km</option>
              <option value="2">2 km</option>
              <option value="3">3 km</option>
              <option value="5">5 km</option>
              <option value="10">10 km</option>
              <option value="20">20 km</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-2">
          {hunt.propertyType === LAND && (
            <div className="w-full">
              <label className="text-gray-700">Lot size in sq.m</label>
              <input
                name="lotSize"
                id="lotSize"
                type="number"
                value={hunt.lotSize ? hunt.lotSize : ''}
                placeholder=""
                onChange={handleChange}
              />
            </div>
          )}
          {/* minBedrooms */}
          {hunt.propertyType !== LAND && (
            <div className="w-full">
              <label className="text-gray-700">Bedrooms</label>
              <select
                value={hunt.bedrooms ? hunt.bedrooms : ''}
                onChange={handleChange}
                className="border border-gray-300 rounded"
                name="bedrooms"
                id="bedrooms"
              >
                <option value="" disabled>
                  {'Select number of rooms'}
                </option>
                <option value="0">Studio</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9+</option>
              </select>
            </div>
          )}
          {/* average Price / budget */}

          <div className="flex flex-col">
            <label className="text-gray-700">
              budget {hunt.isForRent ? '/ month' : ''}
              {hunt.isoCodeCurrency}
            </label>
            <NumericFormat
              id="price"
              name="price"
              className="w-full focus:ring-deehiy rounded border-gray-300 focus:border-gray-300"
              onChange={handlePriceChange}
              value={hunt.price}
              thousandSeparator=" "
              placeholder="0"
              // suffix={currentHunt.isoCodeCurrency || ''}
            />
          </div>
        </div>
      </div>
      <div className="py-4 bg-gray-200 mt-4 px-4">
        {canSave ? (
          <div className="flex justify-around">
            <button className="primary-btn" onClick={handleSave}>
              Save Hunt
            </button>
            <span className="text-gray-500 text-base">{message}</span>
            <button className="secondary-btn" onClick={() => navigate(-1)}>
              Back
            </button>
          </div>
        ) : (
          'CAN NOT SAVE -- missing parameters'
        )}
      </div>
      {/* <pre>{JSON.stringify(currentHunt, null, 2)}</pre>{' '} */}
    </div>
  );
};

export default AdminEditHunt;
