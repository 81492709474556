import React, { useState, useEffect } from 'react';
// import { initialUser } from '../../../initialStates/initialStates';
import InfiniteScroll from 'react-infinite-scroll-component';
import { adjustImageSize } from '../../../utils/adjustImageSize';
import { DropdownItem, CustomDropwdown } from '../navigation/Navigation';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { blockUserService } from '../../../services/communityService';
import { useChat } from '../../../contexts/chat';
import { useAuth } from '../../../contexts/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserTooltip } from '../../square/Square';
import Tooltip from '../../form/Tooltip';
import { useTranslation } from 'react-i18next';
import Contact from '../Contact';

export const DropdownDotsBtn = () => {
  return (
    <BiDotsVerticalRounded
      className="isDropdownBtn text-defaultText hover:bg-gray-100 active:bg-gray-200 focus:outline-none 
      focus:ring-0 active:outline-none 
      active:ring-0 rounded-full text-xl"
    />
  );
};

export const UserCommunityDetails = ({ member }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col h-full w-full text-xs xl:text-sm ">
      <div
        className="font-semibold  flex items-center group relative hover:underline cursor-pointer"
        title={t('Open profile')}
        onClick={() => navigate(`/member/${member.id}`)}
      >
        {member.firstName} {member.lastName}
        <div className="group-hover:visible invisible  w-0 delay-300">
          <UserTooltip user={member} />
        </div>
      </div>
      <div className="flex text-gray-500 flex-col">
        <div>
          {member.isDedicatedProfile && <>{t('Real Estate Agent')}</>}
          {member.isDedicatedProfile && member.isInvestor && ', '}
          {member.isInvestor && <>{t('Investor')}</>}
        </div>
        {member.city}
        {member.city && member.country && ', '}
        {member.country}
      </div>
    </div>
  );
};

export const NetworkUserBox = ({ member }: any) => {
  const { currentUser } = useAuth();
  const { addToContact, chatHandler } = useChat();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [isBlocked, setIsBlocked] = useState(member.isBlocked);
  const [isNetwork, setIsNetwork] = useState(member.isNetwork);

  const chatButtonHandler = async (user: any) => {
    if (currentUser?.id) {
      await chatHandler(user.id, user.firstName || '');
    } else {
      navigate('/login', { state: { redirectTo: location } });
    }
  };

  const blockContact = async (id: number) => {
    try {
      if (currentUser?.id) {
        const res = await blockUserService(id);
        setIsBlocked(res.data.isBlocked);
      } else {
        navigate('/login', { state: { redirectTo: location } });
      }
    } catch (error) {
    } finally {
    }
  };

  const addContactHandler = async (user: any) => {
    try {
      if (currentUser?.id) {
        await addToContact(user.id);
        setIsNetwork(true);
      } else {
        navigate('/login', { state: { redirectTo: location } });
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    setIsBlocked(member.isBlocked);
  }, [member.isBlocked]);

  useEffect(() => {
    setIsNetwork(member.isNetwork);
  }, [member.isNetwork]);

  return (
    <div className="flex w-full py-0 sm:py-2 px-3 shadow-md bg-white h-24 sm:h-32 ">
      <div className="w-5/6 sm:w-1/2 flex h-full ">
        <div className="flex justify-center items-center">
          <div className="relative">
            {member?.isSalesRep && (
              <div className="group absolute top-0 left-0">
                <Tooltip
                  text={t('Deehiy Ambassador')}
                  Component={() => <img className=" w-5 h-5 " src="/images/icons/sales-rep.svg" />}
                />
              </div>
            )}

            <img
              alt=""
              src={
                member.image
                  ? adjustImageSize(member.image)
                  : process.env.REACT_APP_DEFAULT_USER_URL
              }
              className=" h-16 w-16 rounded-full "
            />
          </div>
          <div className="h-16 pl-4 sm:pl-2">
            <UserCommunityDetails member={member} />
          </div>
        </div>
      </div>
      <div className="w-1/6 sm:w-1/2 flex flex-col">
        <div className="flex w-full h-full justify-end items-center">
          {/* <span className="text-gray-200">ID:{member.id}</span> */}

          <CustomDropwdown Btn={<DropdownDotsBtn />}>
            <div className="space-y-5 px-8 sm:px-0 sm:space-y-0">
              {member.id !== currentUser?.id && !isNetwork && !isBlocked && (
                <DropdownItem
                  itemLabel={t('Add contact')}
                  imageUrl="/images/icons/add-contact.svg"
                  onClick={() => addContactHandler(member)}
                />
              )}
              {member.id !== currentUser?.id && isNetwork && !isBlocked && (
                <DropdownItem
                  itemLabel={t('Chat')}
                  imageUrl="/images/icons/post-chat.svg"
                  onClick={() => chatButtonHandler(member)}
                />
              )}
              {member.id !== currentUser?.id && (
                <>
                  {isBlocked ? (
                    <DropdownItem
                      itemLabel={t('Unblock')}
                      imageUrl="/images/icons/unblock-member.svg"
                      onClick={() => blockContact(member.id)}
                    />
                  ) : (
                    <DropdownItem
                      itemLabel={t('Block member')}
                      imageUrl="/images/icons/block-member.svg"
                      onClick={() => blockContact(member.id)}
                    />
                  )}
                </>
              )}
            </div>
          </CustomDropwdown>
        </div>
        <div className="hidden sm:flex w-full h-full  mx-auto duration-300 flex-col justify-center items-center ">
          <div className="flex h-full w-full justify-end items-center">
            {isBlocked ? (
              <div
                className=" p-3 semibold w-full h-full flex text-deehiy-red 
           justify-end duration-500 "
              >
                <span>{t('Blocked')}</span>
              </div>
            ) : (
              <div className="hidden sm:flex w-full  gap-x-4 justify-end">
                {!isNetwork && (
                  <Contact
                    sourcePage="community"
                    chatButtonHandler={() => chatButtonHandler(member)}
                    contact={member}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const List = ({ membersList }: any) => {
  return (
    // <div className="flex flex-wrap gap-7 mt-2">
    //   {membersList.map((member: any, i: number) => {
    //     return <NetworkUserBox member={member} key={i} />;
    //   })}
    // </div>
    <div className="flex flex-col gap-y-0 sm:gap-y-2 mt-2 w-full mx-auto">
      {membersList.map((member: any, i: number) => {
        return <NetworkUserBox member={member} key={i} />;
      })}
    </div>
  );
};

const Community = React.memo(
  ({
    membersList,
    hasMore,
    handleSearch,
  }: {
    hasMore: boolean;
    membersList: any[];
    handleSearch: any;
  }) => {
    return (
      <div>
        <InfiniteScroll
          dataLength={membersList.length}
          next={handleSearch}
          hasMore={hasMore}
          loader={<div className="text-sm text-gray-500 text-center p-12"></div>}
          endMessage={<div className="p-4"></div>}
          className="w-full mt-12 sm:mt-5 px-4 sm:px-0 pb-44"
          style={{ overflow: 'hidden' }}
        >
          <List membersList={membersList} />
        </InfiniteScroll>
      </div>
    );
  }
);

export default Community;
